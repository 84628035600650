<template>
  <div class="application">
    <mobile v-if="isMobileVisible" @toggle-mobile="toggleMobile"></mobile>
    <navbar @toggle-mobile="toggleMobile"></navbar>

    <foot></foot>
  </div>
</template>

<script>
import Navbar from "@/components/navbar.vue";
import mobile from "@/components/mobile.vue";
import Foot from "@/components/foot.vue";

export default {
  name: 'account',
  components: {
    Foot,
    mobile,
    Navbar,
  },
  data() {
    return {
      isMobileVisible: false,
    };
  },
  methods: {
    toggleMobile() {
      this.isMobileVisible = !this.isMobileVisible;
    },
  },
}
</script>

<style scoped>


@media  (max-width: 500px) {

}

@media  (max-width: 950px) {

}
</style>
