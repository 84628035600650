<template>
  <div class="application">
    <mobile v-if="isMobileVisible" @toggle-mobile="toggleMobile"></mobile>
    <navbar @toggle-mobile="toggleMobile"></navbar>
    <section class="form">
      <form action="">
        <h1>Connectez-vous pour enregistrer vos demandes de devis.</h1>
        <input type="email" name="email" id="email" placeholder="Email" required>
        <input type="password" name="password" id="password" placeholder="Mot de passe" required>
        <div class="save">
          <input type="checkbox" name="save" id="save">
          <label for="save"><small>Se souvenir de moi</small></label>
        </div>
        <button type="submit"><span class="material-symbols-outlined"> arrow_forward </span></button>
        <a href="#"><small>Mot de passe oublié ?</small></a>
        <a href="#"><small>Vous rencontrez un problème ?</small></a>
        <a href="espace-inscription"><small>Vous n'avez pas de compte HBC ?</small></a>
      </form>
    </section>
    <foot></foot>
  </div>
</template>

<script>
import Navbar from "@/components/navbar.vue";
import mobile from "@/components/mobile.vue";
import Foot from "@/components/foot.vue";

export default {
  name: 'signin',
  components: {
    Foot,
    mobile,
    Navbar,
  },
  data() {
    return {
      isMobileVisible: false,
    };
  },
  methods: {
    toggleMobile() {
      this.isMobileVisible = !this.isMobileVisible;
    },
  },
}
</script>

<style scoped>
.form{
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

form{
  display: flex;
  width: 100%;
  max-width: 600px;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 0 40px;
}

form h1{
  font-size: 35px;
  font-family: 'Lexend Deca', sans-serif;
  margin-bottom: 50px;
}

form input{
  display: flex;
  width: 100%;
  max-width: 400px;
  height: 50px;
  border-radius: 20px;
  background: none;
  outline: none;
  border: solid 1px #8a8a8a;
  padding: 0 20px;
  margin-bottom: 15px;
}

form button{
  display: flex;
  width: 120px;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  border: solid 1px #8a8a8a;
  margin-bottom: 20px;
}

form a{
  font-size: 15px;
  color: #0283c5;
}

form a:hover{
  text-decoration: underline;
}

.save{
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
}

.save input{
  display: flex;
  width: 15px;
  height: 15px;
  margin-bottom: 0;
  margin-right: 5px;
}

@media  (max-width: 500px) {
}
</style>
