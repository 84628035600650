<template>
  <div class="application">
    <mobile v-if="isMobileVisible" @toggle-mobile="toggleMobile"></mobile>
    <navbar @toggle-mobile="toggleMobile"></navbar>
    <div class="a-four">
      <img src="../assets/banners/banner-chrome.png" alt="">
      <div class="text">
        <div class="h1"><h1>CGU HBC</h1></div>
        <div class="h2"><h2>Conditions Générales d'Utilisation de hbc-group.fr</h2></div>
        <div class="h2"><h2>Date de la dernière mise à jour : 2 Octobre 2023</h2></div>
        <div class="h2"><h2>1. Introduction </h2></div>
        <span>Bienvenue sur hbc-group.fr, le site officiel de High Base Code SARL. En utilisant notre site, vous acceptez ces Conditions Générales d'Utilisation (CGU). Veuillez les lire attentivement. </span>
        <div class="h2"><h2>2. Accès au site </h2></div>
        <span>L'accès à notre site est gratuit. Toutefois, vous devez avoir les équipements nécessaires pour accéder à Internet et comprendre que les frais associés à cette connexion restent à votre charge. </span>
        <div class="h2"><h2>3. Propriété intellectuelle </h2></div>
        <span>Tout le contenu de ce site (textes, images, logos, etc.) est la propriété de High Base Code SARL ou de ses partenaires et est protégé par les lois sur le droit d'auteur. Toute utilisation, reproduction ou distribution non autorisée de tout ou partie du contenu de ce site est strictement interdite. </span>
        <div class="h2"><h2>4. Limitation de responsabilité </h2></div>
        <span>High Base Code SARL s'efforce d'assurer l'exactitude et la mise à jour des informations sur ce site. Toutefois, nous ne garantissons ni l'exactitude, ni l'exhaustivité des informations fournies. Nous ne sommes pas responsables des dommages directs ou indirects résultant de l'utilisation de ce site. </span>
        <div class="h2"><h2>5. Comptes utilisateurs</h2></div>
        <span>Si vous créez un compte sur notre site, vous êtes responsable de la sécurité de votre compte et de son contenu. High Base Code SARL se réserve le droit de suspendre ou de supprimer un compte en cas d'activité suspecte ou de violation de ces CGU. </span>
        <div class="h2"><h2>6. Modifications</h2></div>
        <span>High Base Code SARL se réserve le droit de modifier ou d'interrompre, temporairement ou définitivement, tout ou partie du site sans préavis.</span>
        <div class="h2"><h2>7. Liens externes</h2></div>
        <span>Notre site peut contenir des liens vers d'autres sites qui ne sont pas exploités par nous. Ces liens sont fournis pour votre commodité et nous n'endossons pas le contenu de ces sites externes. Nous ne sommes pas responsables du contenu ou des pratiques de ces sites. </span>
        <div class="h2">8. Loi applicable et juridiction</div>
        <span>Ces CGU sont régies par le droit français. Tout litige relatif à l'interprétation ou à l'exécution de ces CGU sera de la compétence exclusive des tribunaux français. </span>
        <div class="h2"><h2>9. Contact </h2></div>
        <span>Pour toute question ou réclamation concernant ces CGU, veuillez nous contacter à support@hbcgroup.fr, </span>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import Navbar from "@/components/navbar.vue";
import mobile from "@/components/mobile.vue";
import Foot from "@/components/foot.vue";

export default {
  name: 'cgu',
  components: {
    Foot,
    mobile,
    Navbar,
  },
  data() {
    return {
      isMobileVisible: false,
    };
  },
  methods: {
    toggleMobile() {
      this.isMobileVisible = !this.isMobileVisible;
    },
  },
}
</script>

<style scoped>
.a-four{
  display: flex;
  width: 100%;
  padding: 50px;
  justify-content: center;
  align-items: center;
  background: black;
  flex-direction: column;
}

.a-four img{
  display: flex;
  width: 100%;
  max-width: 70%;
  object-fit: cover;
}

.a-four .text{
  display: flex;
  width: 100%;
  max-width: 21cm;
  flex-direction: column;
  padding: 20px;
  color: white;
  margin-top: 50px;
}

.text span{
  margin-bottom: 20px;
}


.h1 h1{
  display: flex;
  font-family: 'Lexend Deca', sans-serif;
}

.h1{
  display: flex;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.h2{
  display: flex;
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
}

@media  (max-width: 500px) {
  .a-four img{
    display: none;
  }
  .a-four .text{
    padding: 0;
  }
}
</style>
