<template>
  <div class="application">
    <mobile v-if="isMobileVisible" @toggle-mobile="toggleMobile"></mobile>
    <navbar @toggle-mobile="toggleMobile"></navbar>
    <div class="banner">
      <img src="../assets/banners/banner-chrome.png" class="pc">
      <img src="../assets/banners/banner-chrome-mobile.png" class="mobile">
    </div>
    <div class="slogan">
      <h1>Votre partenaire parfait</h1>
    </div>
    <div class="banner_slogan">
      <img src="../assets/banners/banner-slogan.png" class="pc">
      <img src="../assets/banners/banner-slogan-mobile.png" class="mobile">
    </div>

    <section class="grid_square">
      <a href="#">
        <img src="../assets/banners/square-etudik.png" alt="" class="square">
      </a>
      <a href="#">
        <img src="../assets/banners/square-creator.png" alt="" class="square">
      </a>
    </section>

    <div class="banner-square">
      <a href="#">
        <img src="../assets/banners/square-hbc.png" class="pc-square">
      </a>
    </div>

    <div class="slogan">
      <h1 class="spc">Nous vous proposons une plateforme dédier pour vous</h1>
      <h1 class="smobile">Une plateforme pour vous</h1>
      <span>L'équipe HBC a développé une plateforme pour vous mettre en relationnel avec des professionnels dans le domaine de l'informatique.</span>
      <div class="cta">
        <a href="#">IT JOBS</a>
      </div>
    </div>

    <div class="banner_slogan">
      <a href="#">
        <img src="../assets/banners/banner-articles.png" class="banner-art">
      </a>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import Navbar from "@/components/navbar.vue";
import mobile from "@/components/mobile.vue";
import Foot from "@/components/foot.vue";

export default {
  name: 'index',
  components: {
    Foot,
    mobile,
    Navbar,
  },
  data() {
    return {
      isMobileVisible: false,
    };
  },
  methods: {
    toggleMobile() {
      this.isMobileVisible = !this.isMobileVisible;
    },
  },
}
</script>

<style scoped>
.banner{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: black;
  padding-top: 50px;
  padding-bottom: 50px;
}

.banner img{
  display: flex;
  width: 100%;
  object-fit: cover;
}

.banner .pc{
  display: flex;
  max-width: 70%;
  min-width: 650px;
}

.banner .mobile{
  display: none;
}

.banner_slogan{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: black;
  padding-top: 50px;
}

.banner_slogan a{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.banner_slogan img{
  display: flex;
  width: 100%;
  object-fit: cover;
}

.banner_slogan .pc{
  display: flex;
  max-width: 100%;
}

.banner_slogan .mobile{
  display: none;
}

.banner_slogan .banner-art{
  display: flex;
  max-width: 40%;
  margin-bottom: 100px;
}

.slogan{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: center;
  padding: 150px 50px;
}

.slogan span{
  font-size: 30px;
  padding: 0 60px;
  text-align: center;
  margin-top: 20px;
}

.slogan h1{
  text-align: center;
  font-size: 60px;
  font-family: 'Lexend Deca', sans-serif;
  text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
  0px 8px 13px rgba(0,0,0,0.1),
  0px 18px 23px rgba(0,0,0,0.1);
}

.slogan .spc{
  display: flex;
}

.slogan .smobile{
  display: none;
}

.grid_square{
  display: flex;
  width: 100%;
  grid-gap: 10px;
  padding: 10px;
}

.square{
  display: flex;
  width: 100%;
  object-fit: cover;
}

.grid_square a{
  display: flex;
  width: 100%;
  padding: 50px;
  background: black;
}

.banner-square{
  display: flex;
  width: 100%;
  padding: 0 10px;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.banner-square a{
  background-color: black;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.banner-square img{
  display: flex;
  width: 100%;
  max-width: 700px;
  object-fit: cover;
}

.cta a{
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  height: 50px;
  background-color: black;
  color: white;
  border-radius: 50px;
  font-weight: bold;
  margin-top: 50px;
}

@media  (max-width: 500px) {
  .banner_slogan .banner-art {
    max-width: 100%;
  }
  .slogan .spc {
    display: none;
  }

  .slogan h1{
    font-size: 50px;
  }

  .slogan .smobile{
    display: flex;
  }

  .slogan span{
    font-size: 20px;
    margin-top: 40px;
    padding: 0;
  }

  .banner .pc{
    display: none;
  }

  .banner .mobile{
    display: flex;
    width: 100%;
  }

  .banner_slogan .pc{
    display: none;
  }

  .banner_slogan .mobile{
    display: flex;
    width: 100%;
  }

  .grid_square{
    flex-direction: column;
    padding: 0;
    margin-top: 10px;
    grid-gap: 10px;
  }

  .grid_square a{
    border-radius: 0px;
  }

  .banner-square img{
    width: 100%;
  }

  .banner-square{
    padding: 0;
    margin-top: 10px;
  }

  .banner-square a{
    border-radius: 0px;
    padding: 50px;
  }
}

@media  (max-width: 950px) {
  .grid_square {
    flex-direction: column;
  }
}
</style>
