<template>
  <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
  <footer>
    <img src="../assets/logos/icon-blanc.png">
    <small class="text">Chez HBC, l'innovation est notre moteur. Nous sommes passionnés par la création de solutions informatiques qui transforment et stimulent votre entreprise. Nos équipes de développeurs chevronnés sont constamment à l'affût des dernières technologies et tendances du secteur pour offrir des produits à la pointe de l'excellence.</small>
    <div class="footer">
      <div class="box">
        <h3>Mentions</h3>
        <div class="mentions">
          <a href="/cgu" target="_blank"><span class="material-symbols-outlined"> description </span><small>CGU</small></a>
          <a href="/mention-legal"><span class="material-symbols-outlined"> description </span><small>Mention Légal</small></a>
        </div>
      </div>
      <div class="box">
        <h3>High Base Code</h3>
        <small>45 BD LEAU</small>
        <small>07 57 83 33 17</small>
        <small>contact@hbc-group.fr</small>
        <small><a href="/help" class="help">Besoin d'aide.</a></small>
      </div>
      <div class="box">
        <h3>Rejoins-nous</h3>
        <a href="https://www.linkedin.com/company/high-base-code/" target="_blank"><small>Linkedin</small></a>
        <a href="https://www.instagram.com/high_base_code/" target="_blank"><small>Instagram</small></a>
        <a href="https://www.tiktok.com/@highbasecode?lang=fr" target="_blank"><small>TikTok</small></a>
      </div>
    </div>
    <small>© 2023 hbc-group.fr - Tous droits réservés.</small>
  </footer>
</template>

<script>
export default {
  name: 'foot',
  components: {
  },
}
</script>

<style scoped>
.help{
  color: #0066CC;
}

.mentions{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: white;
}

.mentions a{
  display: flex;
  width: 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  height: 70px;
}

.box a small{
  color: white;
}

footer{
  display: flex;
  width: 100%;
  bottom: 0;
  background: #1A1D1F;
  color: white;
  border-top: solid #dfdfdf 1px;
  padding: 20px;
  flex-direction: column;
  align-items: center;
}

footer img{
  display: flex;
  width: 60px;
}

footer .text{
  text-align: center;
  margin-bottom: 70px;
  display: flex;
  width: 100%;
  max-width: 700px;
}

.footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.footer .box{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  height: 100px;
}

@media  (max-width: 850px) {
  .footer{
    flex-direction: column;
    justify-content: center;
  }
  footer{
    text-align: center;
  }

  .footer .box{
    margin: 10px 0;
  }

  footer .text{
    display: none;
  }
}
</style>
